<template>
  <div>
    <nav-bar class="nav-bar title">
      <img slot="left" class="imgsize44" src="~images/fanhui.png" alt="" @click="onBack">
      <div slot="center"><slot></slot></div>
    </nav-bar>
    <div class="box"></div>
  </div>
</template>

<script>
  import NavBar from 'components/common/navbar/NavBar'
  export default {
    name: 'TitleTop',
    components: {
      NavBar
    },
    methods: {
      onBack() {
        this.$router.back();
      },
    }
  }
</script>

<style scoped>
.title{
  width: 100%;
  max-width: 750px;
  position: fixed;
  /* left: 0; */
  top: 0;
}
.box{
  width: 100%;
  height: 44px;
}
</style>
