import { request,post } from './request'
import axios from 'axios'

//优品系统公告
export function announcements(page=1,size=20) {
    return request({
        url: '/announcements',
        params: {
            page,
            size
        }
    })
}
//优品通知公告
export function notifications(page=1,size=20) {
    return request({
        url: '/notifications',
        params: {
            page,
            size
        }
    })
}

//意见反馈
export function feed_backs(content,images=[]) {
    return request({
        url: '/feed_backs',
        method: 'post',
        data:{
            content,
            images
        }
    })
}

//实名认证
export function certification_state() {
    return request({
        url: '/certification_state'
    })
}
//实名认证
export function idcard_cert(real_name,identity_number,front_of_id_card_id) {
    return request({
        url: '/identity_certification',
        method: 'put',
        data: {real_name,identity_number,front_of_id_card_id}
    })
}

//修改头像
export function upload_header(avatar_id) {
    return request({
        url: '/avatar',
        method: 'put',
        data: {avatar_id}
    })
}

//图片上传
export function upload_img(file, type = 'avatar') {
    
    let param = new FormData()  // 创建form对象
    param.append('file', file)  // 通过append向form对象添加数据
    param.append('type', type) // 添加form表单中其他数据
    /*
    let config = {
        headers: {'Content-Type': 'multipart/form-data','Accept': 'application/json'}
      }
    return post('/images',param,config);
    */
    
    return request({
        url: '/images',
        method: 'post',
        headers: { 
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
         },
        data: param
    })
}
//查看用户信息
export function member() {
    return request({
        url: '/member'
    })
}
//修改用户信息
export function update_member(name, district_id = 0, gender = 0) {
    return request({
        url: '/member',
        method: 'patch',
        data: {
            name,
            district_id,
            gender
        }
    })
}

//修改手机号
export function update_mobile(mobile, password, sms_code) {
    return request({
        url: '/mobile',
        method: 'patch',
        data: {
            mobile,
            password,
            sms_code
        }
    })
}
//修改登录密码
export function update_psw(password, sms_code) {
    return request({
        url: '/password/reset',
        method: 'put',
        data: {
            password,
            password_confirmation: password,
            sms_code
        }
    })
}
//修改登录密码
export function update_pay_psw(mobile, payment_password, sms_code) {
    return request({
        url: '/payment_password/reset',
        method: 'put',
        data: {
            mobile,
            payment_password_confirmation: payment_password,
            payment_password,
            sms_code
        }
    })
}
//个人中心
export function dashboard() {
    return request({
        url: '/dashboard'
    })
}
//查看用户预估收益
export function calculate_promotions() {
    return request({
        url: '/calculate_promotions'
    })
}
//未读通知数
export function unread_count() {
    return request({
        url: '/notifications/unread_count'
    })
}
//淘宝搜索
export function tbk_goods_search(q, page_no = 1, page_size = 20, is_tmall = false) {
    return request({
        url: '/tbk/goods_search',
        params: {
            q,
            page_no,
            page_size,
            is_tmall
        }
    })
}
//搜索京东
export function jingdong_products(keyword, page = 1, page_size = 20) {
    return request({
        url: '/jingdong/products',
        params: {
            keyword,
            page,
            page_size
        }
    })
}
//搜索拼多多
export function pinduoduo_goods_search(keyword, page = 1, size = 20) {
    return request({
        url: '/pinduoduo/goods_search',
        params: {
            keyword,
            page,
            size
        }
    })
}
//优品推荐商品
export function pingduoduo_top_goods(page = 1, size = 20) {
    return request({
        url: '/pinduoduo/top_goods',
        params: {
            page,
            size
        }
    })
}
//拼多多商品详情
export function pinduoduo_goods_detail(goods_id) {
    return request({
        url: '/pinduoduo/goods_detail',
        params: {
            goods_id
        }
    })
}

//淘宝商品详情
export function tbk_goods_detail(num_iids) {
    return request({
        url: '/tbk/goods_detail',
        params: {
            num_iids
        }
    })
}
//拼多多商品推广链接
export function pinduoduo_goods_promotion_url(goods_id) {
    return request({
        url: '/pinduoduo/goods_promotion_url',
        params: {
            goods_id
        }
    })
}
//京东链接转换
export function jingdong_promotion_url(original_url) {
    return request({
        url: '/jingdong/promotion_url',
        params: {
            original_url
        }
    })
}
//预估收益明细
export function calculate_promotions_detail(scope='today'){
    return request({
        url: '/calculate_promotions/detail',
        params: {
            scope
        }
    })
}
//我的团队
export function downlines(page=1,size=20){
    return request({
        url: '/downlines',
        params: {
            page,
            size
        }
    })
}
//搜索团队成员
export function downlines_search(mobile){
    return request({
        url: '/downlines/search',
        params: {
            mobile
        }
    })
}
//查看我的邀请人
export function inviter(){
    return request({
        url: '/inviter'
    })
}
//提现统计
export function overview(){
    return request({
        url: '/withdrawals/overview'
    })
}
//提现
export function withdrawals(amount,payment_password,arrival_periods=1,channel='alipay'){
    return request({
        url: '/withdrawals',
        method:'post',
        data:{
            amount,
            channel,
            payment_password,
            arrival_periods
        }
    })
}
//提现记录
export function get_withdrawals(page=1,size=1000){
    return request({
        url: '/withdrawals',
        params: {
            page,
            size
        }
    })
}
//收支明细
export function account_logs(type='balance',page=1,size=1000){
    return request({
        url: '/account_logs',
        params: {
            type,
            page,
            size
        }
    })
}

//京东订单
export function jingdong_orders(state='',page=1,size=1000){
    return request({
        url: '/jingdong/orders',
        params: {
            state,
            page,
            size
        }
    })
}

//淘宝订单
export function tbk_orders(state='',page=1,size=1000){
    return request({
        url: '/tbk/orders',
        params: {
            state,
            page,
            size
        }
    })
}
//拼多多订单
export function pinduoduo_orders(state='',page=1,size=1000){
    return request({
        url: '/pinduoduo/orders',
        params: {
            state,
            page,
            size
        }
    })
}
//检查支付宝绑定状态
export function alipay_user_info(){
    return request({
        url: '/alipay_user_info',
        method:'get',
        headers:{
            'Content-Type':'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        }
    })
}
//解除支付宝绑定状态
export function delete_alipay_user_info(){
    return request({
        url: '/alipay_user_info',
        method:'delete',
        headers:{
            'Content-Type':'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        }
    })
}
//支付宝授权请求参数
export function alipay_user_info_edit(){
    return request({
        url: '/alipay_user_info/edit',
        headers:{
            'Content-Type':'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        }
    })
}
//二维码分享背景图
export function share_picture(){
    return request({
        url: '/share_picture'
    })
}
//邀请链接
export function invitation_url(){
    return request({
        url: '/invitation_url'
    })
}
//把所有通知标记为已读
export function mark_all_as_read(){
    return request({
        url: '/notifications/mark_all_as_read',
        method: 'patch'
    })
}
//退出登录
export function logout(){
    return request({
        url: '/auth_token',
        method: 'delete'
    })
}
